.container {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.toolbar {
    background: #F0F0F0;
}

.label {
    margin: 10px;
}