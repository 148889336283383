.container {
    display: flex;
    flex-direction: row
}

.date {
    margin: auto;
}

.expired {
    text-decoration: line-through;
}